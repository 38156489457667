import React, { useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';

import Stepper from '../navigation/Stepper';
import QuoteFooter from '../UI/QuoteFooter';

import Colors from '../../../constants/Colors';

import { roofMaterials } from './constants/roofData'

import NewRoofOption from '../UI/NewRoofOption';

const RoofMaterialScreen = ({ navigation, formData, onUpdateFormData }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [requiredError, setRequiredError] = useState(false);
  const [newRoofType, setNewRoofType] = useState(formData.newRoofType);

  const handleBack = () => {
    onUpdateFormData({ ...formData, newRoofType });
    navigation.navigate('RoofDetails');
  }

  const handleNext = () => {
    if (!newRoofType) {
      setRequiredError(true);
    } else {
      onUpdateFormData({ ...formData, newRoofType });
      navigation.navigate('ReviewQuote');
    }
  }

  const handleTypeChange = (val) => {
    setNewRoofType(val);
    setRequiredError(false);
  };

  return (
    <PaperProvider>
      <Stepper currentScreen={'RoofMaterial'} />
      <ScrollView style={isMobile ? mobileStyles.screen : styles.screen}>
        <View style={styles.mainHeaderRow}>
          <Text style={styles.mainHeader}>What does your dream roof look like?</Text>
        </View>
        <View style={styles.subHeader}>
          <Text style={styles.header}>Select your top choice. Don't worry, we'll provide cost estimates for all your options.</Text>
        </View>
        <View>
          {roofMaterials.map((data, index) => {
            return (
              <React.Fragment key={data.id}>
                <NewRoofOption
                  key={index}
                  label={data.label}
                  details={data.details}
                  image={data.image}
                  id={data.id}
                  checked={newRoofType}
                  handleTypeChange={handleTypeChange.bind(this)}
                />
                {(index + 1) < roofMaterials.length && <View style={styles.separator}></View>}
              </React.Fragment>
            )
          })}
        </View>
        {requiredError && <Text style={styles.errorText}>Please select an option above</Text>}
      </ScrollView>
      <QuoteFooter
        navigation={navigation}
        nextScreenLabel="Review Quote"
        previousScreenLabel={'Back'}
        onPrevious={handleBack}
        onNext={handleNext}
      />
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  screen: {
    padding: 50,
    backgroundColor: Colors.whiteBackground,
    flex: 1,
  },
  mainHeaderRow: {
    display: 'block',
    marginBottom: '3rem'
  },
  mainHeader: {
    fontSize: '24px'
  },
  subHeader: {
    display: 'block',
    marginBottom: '2rem'
  },
  header: {
    fontSize: '18px',
    display: 'block'
  },
  quoteContainer: {
    flexDirection: 'row',
    paddingTop: 3
  },
  quoteText: {
    marginLeft: 5,
    paddingTop: 10,
    marginRight: 10
  },
  errorText: {
    color: 'red',
    display: 'block',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  separator: {
    width: '770px',
    borderBottomColor: 'gainsboro',
    borderBottomWidth: '1px',
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const mobileStyles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: Colors.whiteBackground,
    flex: 1,
  },
});

export default RoofMaterialScreen;