import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import {CheckIcon} from 'native-base';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';

import Colors from '../../../constants/Colors';
import {ProgressBar} from "react-native-paper";

const Stepper = ({ currentScreen }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const screenValidities = {
    contactInfo: ['RoofDetails', 'RoofMaterial', 'ReviewQuote'].includes(currentScreen),
    roofDetail: ['RoofMaterial', 'ReviewQuote'].includes(currentScreen),
    roofMaterial: 'ReviewQuote' === currentScreen,
    reviewQuote: true,
  }

  const getProgressBarText = currentScreen => {
    if (currentScreen === 'ContactInfo') return 'Provide Contact Info';
    if (currentScreen === 'RoofDetails') return 'Provide Roof Details';
    if (currentScreen === 'RoofMaterial') return 'Select New Roof Material';
    if (currentScreen === 'ReviewQuote') return 'Review Quote';
  }

  const getProgressBarStep = currentScreen => {
    if (currentScreen === 'ContactInfo') return 0.25;
    if (currentScreen === 'RoofDetails') return 0.5;
    if (currentScreen === 'RoofMaterial') return 0.75;
    if (currentScreen === 'ReviewQuote') return 1;
  }

  return !isMobile ? (
      <View style={styles.container}>
        <TouchableWithoutFeedback
          style={styles.button}
        >
          <View
            style={currentScreen === 'ContactInfo' || screenValidities?.contactInfo ?
              styles.pillSelected : styles.pillUnselected
            }
          >
            {screenValidities?.contactInfo ?
                <CheckIcon style={styles.checkmark} />
              :
              <Text style={styles.pillText}>1</Text>
            }
          </View>
          <Text style={styles.stepperText}>Provide Contact Info</Text>
        </TouchableWithoutFeedback>
        <View style={styles.spacer} />
        <TouchableWithoutFeedback
          style={styles.button}
        >
          <View
            style={currentScreen === 'RoofDetails' || screenValidities?.roofDetail ?
              styles.pillSelected : styles.pillUnselected
            }
          >
            {screenValidities?.roofDetail ?
                <CheckIcon style={styles.checkmark} />
              :
              <Text style={styles.pillText}>2</Text>
            }
          </View>
          <Text style={styles.stepperText}>Provide Roof Details</Text>
        </TouchableWithoutFeedback>
        <View style={styles.spacer} />
        <TouchableWithoutFeedback
          style={styles.button}
        >
          <View
            style={currentScreen === 'RoofMaterial' || screenValidities?.roofMaterial ?
              styles.pillSelected : styles.pillUnselected
            }
          >
            {screenValidities?.roofMaterial ?
                <CheckIcon style={styles.checkmark} />
              :
              <Text style={styles.pillText}>3</Text>
            }
          </View>
          <Text style={styles.stepperText}>Select New Roof Material</Text>
        </TouchableWithoutFeedback>
        <View style={styles.spacer} />
        <TouchableWithoutFeedback
          style={styles.button}
        >
          <View
            style={currentScreen === 'ReviewQuote' ?
              styles.pillSelected : styles.pillUnselected
            }
          >
            <Text style={styles.pillText}>4</Text>
          </View>
          <Text style={styles.stepperText}>Review Quote</Text>
        </TouchableWithoutFeedback>
      </View>
  ) : (
      <View style={styles.progressBar}>
        <ProgressBar progress={getProgressBarStep(currentScreen)} color={Colors.elementSelected} />
        <Text style={styles.progressBarText}>{getProgressBarText(currentScreen)}</Text>
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.lightBackground,
    paddingVertical: 15,
    paddingHorizontal: 30
  },
  button: {
    backgroundColor: Colors.lightBackground,
    flexDirection: 'row'
  },
  pillText: {
    paddingBottom: 1,
    fontSize: 14,
    color: 'white',
    width: '100%',
    textAlign: 'center'
  },
  pillUnselected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: Colors.elementUnselected
  },
  pillSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: Colors.elementSelected
  },
  stepperText: {
    fontSize: 14,
    paddingTop: 7,
    paddingLeft: 15
  },
  spacer: {
    width: 30,
    height: '50%',
    borderBottomColor: '#BEBEBE',
    borderBottomWidth: 1
  },
  checkmark: {
    fontSize: 23,
    color: 'white'
  },
  progressBar: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: Colors.whiteBackground
  },
  progressBarText: {
    margin: 'auto',
    marginTop: 10,
    fontWeight: '600'
  },
})

export default Stepper;