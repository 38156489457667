import placeholderImg from "../../../../assets/images/placeholder.png";
import metalRoof from "../../../../assets/images/metalRoof.jpg";
import basicShingle from "../../../../assets/images/basicShingle.jpg";
import tileRoof from "../../../../assets/images/tileRoof.png";
import flatRoof from "../../../../assets/images/flatRoof.jpg";
import builtInGarage from "../../../../assets/images/builtInGarage.jpg";
import detachedGarage from "../../../../assets/images/detachedGarage.jpg";
import seperateGarage from "../../../../assets/images/seperateGarage.jpg";
import ideal50YrShingle from "../../../../assets/images/ideal50YrShingle.png";
import idealBasicShingle from "../../../../assets/images/idealBasicShingle.jpg";
import idealMetal from "../../../../assets/images/idealMetal.jpg";
import simpleCut from "../../../../assets/images/simpleCut.png";
import complicatedCut from "../../../../assets/images/complicatedCut.png";
import lowPitch from "../../../../assets/images/low_pitch.png";
import averagePitch from "../../../../assets/images/average_pitch.png";
import steepPitch from "../../../../assets/images/steep_pitch.png";
import solarRoof from "../../../../assets/images/solar-roof.jpg";

export const roofData = [
  {
    section: [
      {
        label: "Metal",
        image: metalRoof,
      },
      {
        label: "Shingle",
        image: basicShingle,
      },
      {
        label: "Tile",
        image: tileRoof,
      },
      {
        label: "Mod-Bit",
        image: flatRoof,
      },
    ],
    name: "currentRoofType",
    text: "What is your current roof type?"
  },
  {
    section: [
      {
        label: "Low to No Slope",
        image: lowPitch,
      },
      {
        label: "Normal Pitch",
        image: averagePitch,
      },
      {
        label: "Steep Pitch",
        image: steepPitch,
      },
    ],
    name: "currentRoofPitch",
    text: "What is your current roof pitch?"
  },
  {
    section: [
      {
        label: "Simple",
        image: simpleCut,
        name: "currentRoofCut",
      },
      {
        label: "Complex",
        image: complicatedCut,
        name: "currentRoofCut",
      },
    ],
    name: "currentRoofCut",
    text: "What is your current roof cut?"
  },
  {
    section: [
      {
        label: "Attached",
        image: seperateGarage,
      },
      {
        label: "Separate",
        image: detachedGarage,
      },
      {
        label: "Inside",
        image: builtInGarage,
      },
    ],
    name: "garageType",
    text: "What is your current garage type?"
  },
];

export const roofMaterials = [
  {
    label: "Basic Shingle - Good",
    simpleLabel: "Basic Shingle",
    details: "25-year shingles, come with a 25-year prorated limited warranty and are available in a variety of colors.  These shingles have a 65-mph wind rating. Also known as 3-tabs these are the more economical choice for asphalt shingle roofing systems. Manufacturers include Owens Corning, GAF & CertainTeed. We provide a 5-year labor warranty.",
    image: idealBasicShingle,
    id: "good",
    quoteId: 'basic_shingle'
  },
  {
    label: "50-Year Shingle - Better",
    simpleLabel: "50-Year Shingle",
    details: "HDZ / Laminate / Architectural / Dimensional / Lifetime Shingles come with a limited lifetime warranty on material and are available in a variety of designer colors. These shingles have a 130-mph wind rating. This roof system qualifies for a GAF / Owens Corning / CertainTeed 50-Year Warranty. The Golden Pledge 50-Year Warranty by GAF includes a 25-Year Labor Warranty aka Free Leak Repairs should one arise within those 25-Years.",
    image: ideal50YrShingle,
    id: "better",
    quoteId: '50_year_shingle'
  },
  {
    label: "Standing Seam Metal - Best",
    simpleLabel: "Standing Seam Metal",
    details: "24-Gauge Standing Seam Snap-Lock Metal Roofs have up to a 40-Year Limited Paint Warranty and come in a variety of designer colors. Galvalume color carries a 20-Year Material Warranty, non-prorated, which protects the full roof value for up to 20 years. These panels have a 140-mph wind rating. This roofing system qualifies for a home insurance discount up to 20% with it's Class 4 Impact Resistance Rating. Metal is recyclable & panels contain about 30% recycled content from pre-consumer & post-consumer use. We provide a 10-Year Labor Warranty.",
    image: idealMetal,
    id: "best",
    quoteId: 'standing_seam_metal'
  },
  {
    label: "GAF Energy Solar Roof - Green",
    simpleLabel: "GAF Energy Solar Roof",
    details: "Introducing Timberline Solar™. It protects like a roof, installs like a roof – and produces clean energy, from the world's first nailable solar shingle. Includes a 25-Year Energy Production Guarantee, the only warranty like it in the industry! Custom quote necessary.",
    image: solarRoof,
    id: "green",
    quoteId: 'gaf_solar',
  }
];
