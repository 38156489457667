import React, {useEffect, useReducer, useRef} from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { Provider as PaperProvider, TextInput, Title, Card,  } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';

import Stepper from '../navigation/Stepper';
import QuoteFooter from '../UI/QuoteFooter';
import Colors from '../../../constants/Colors';
import Theme from '../../../constants/Theme';
import { roofData } from './constants/roofData'
import {CheckIcon} from "native-base";
import {GooglePlacesAutocomplete} from "react-native-google-places-autocomplete";
import KeyEvent from "react-native-keyevent";

const RoofDetailsScreen = ({ formData, onUpdateFormData, navigation }) => {
  const ref = React.useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [state, dispatch] = useReducer(reducer, {
    address: formData.address,
    city: formData.city,
    addressState: formData.addressState,
    zip: formData.zip,
    currentRoofType: formData.currentRoofType,
    currentRoofPitch: formData.currentRoofPitch,
    currentRoofCut: formData.currentRoofCut,
    garageType: formData.garageType,
  });

  const {
    address,
    city,
    addressState,
    zip,
    currentRoofType,
    currentRoofPitch,
    currentRoofCut,
    garageType,
    ...errors
  } = state;

  const {
    addressError,
    cityError,
    addressStateError,
    zipError,
  } = errors;

  const isValid = address?.length && !addressError && city?.length && !cityError && addressState?.length && !addressStateError && zip?.length && !zipError && currentRoofType?.length && currentRoofPitch?.length && currentRoofCut?.length && garageType?.length;

  const handleBack = () => {
    onUpdateFormData({ ...formData, ...state });
    navigation.navigate('ContactInfo');
  }

  const handleNext = () => {
    if (isValid) {
      onUpdateFormData({ ...formData, ...state });
      navigation.navigate('RoofMaterial');
    } else {
      dispatch({ type: 'validateAll' })
    }
  }

  const handleRoofOptions = (fieldName, value) => {
    switch (fieldName) {
      case 'currentRoofType':
        dispatch({
          type: 'setCurrentRoofType',
          payload: value,
        });
        dispatch({
          type: 'validateCurrentRoofType',
        });
        break;
      case 'currentRoofPitch':
        dispatch({
          type: 'setCurrentRoofPitch',
          payload: value,
        });
        dispatch({
          type: 'validateCurrentRoofPitch',
        });
        break;
      case 'currentRoofCut':
        dispatch({
          type: 'setCurrentRoofCut',
          payload: value,
        });
        dispatch({
          type: 'validateCurrentRoofCut',
        });
        break;
      case 'garageType':
        dispatch({
          type: 'setGarageType',
          payload: value,
        });
        dispatch({
          type: 'validateGarageType',
        });
        break;
      default:
        break;
    }
  }

  const handleSetAddress = (details) => {
    let streetNumber, route, locality, state, postalCode, postalCodeSuffix;
    details?.address_components?.forEach(detail => {
      if (detail.types.includes('street_number')) {
        streetNumber = detail.long_name;
      } else if (detail.types.includes('route')) {
        route = detail.long_name
      } else if (detail.types.includes('locality')) {
        locality = detail.long_name
      } else if (detail.types.includes('postal_code')) {
        postalCode = detail.long_name
      } else if (detail.types.includes('postalCodeSuffix')) {
        postalCodeSuffix = detail.long_name
      } else if (detail.types.includes('administrative_area_level_1')) {
        state = detail.long_name
      }
    })
    dispatch({
      type: 'setFullAddress',
      payload: {
        address: `${streetNumber} ${route}`,
        city: locality,
        addressState: state,
        zip: postalCode + (postalCodeSuffix ? ` ${postalCodeSuffix}` : '')
      }
    })
  }

  const addressRef = useRef()

  useEffect(() => {
    addressRef.current?.setAddressText(address);
    KeyEvent.onKeyDownListener(e => console.log(e))
  }, []);

  return (
    <PaperProvider>
      <Stepper currentScreen={'RoofDetails'} />
      <ScrollView ref={ref} style={isMobile ? mobileStyles.screen : styles.screen}>
        <Title style={styles.title}>Tell us about your current roof.</Title>
        <View style={styles.addressSection}>
          <Text style={styles.text}>Please provide your physical address.</Text>
          <View style={styles.input} on>
            <GooglePlacesAutocomplete
                ref={addressRef}
                onPress={(_data, details) => handleSetAddress(details)}
                query={{ key: 'AIzaSyBALM3z8XF08gFox2bEuYC2ivNGroPO-Rc', language: 'en', components: 'country:us', }}
                requestUrl={{
                  url: 'https://damp-dawn-25329.herokuapp.com/https://maps.googleapis.com/maps/api',
                  useOnPlatform: 'web',
                }}
                styles={{
                  fontSize: '16px',
                  textInput: {
                    paddingVertical: '0',
                    paddingHorizontal: '0',
                    height: 56,
                    fontSize: 16,
                  }
                }}
                minLength={2}
                fetchDetails
                textInputProps={{
                  InputComp: TextInput,
                  theme: Theme,
                  mode: 'outlined',
                  label: 'Address',
                  styles: styles.textField,
                  value: address,
                  error: addressError,
                  onChangeText: val => dispatch({
                    type: 'setAddress',
                    payload: val,
                  }),
                }}
            />
            {!addressError ? <Text style={styles.disclaimer}>*Required</Text> : <Text style={styles.errorMessage}>A valid address is required.</Text>}
          </View>
          {!isMobile ? (
              <View style={styles.row}>
                <View style={[styles.rowInput, styles.input, styles.addressField]}>
                  <TextInput
                      mode='outlined'
                      label='City'
                      theme={Theme}
                      style={styles.textField}
                      value={city}
                      error={cityError}
                      onChangeText={val => dispatch({
                        type: 'setCity',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateCity',
                        payload: e.target.value,
                      })}
                  />
                  {!cityError ? <Text style={styles.disclaimer}>*Required</Text> : <Text style={styles.errorMessage}>A valid city is required.</Text>}
                </View>
                <View style={[styles.rowInput, styles.input, styles.addressField]}>
                  <TextInput
                      mode='outlined'
                      label='State'
                      theme={Theme}
                      style={styles.textField}
                      value={addressState}
                      error={addressStateError}
                      onChangeText={val => dispatch({
                        type: 'setAddressState',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateAddressState',
                        payload: e.target.value,
                      })}
                  />
                  <Text style={addressStateError ? styles.errorMessage : styles.disclaimer}>* Required</Text>
                </View>
                <View style={[styles.rowInput, styles.input]}>
                  <TextInput
                      mode='outlined'
                      label='Zip'
                      theme={Theme}
                      style={styles.textField}
                      value={zip}
                      error={zipError}
                      onChangeText={val => dispatch({
                        type: 'setZip',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateZip',
                        payload: e.target.value,
                      })}
                  />
                  {!zipError ? <Text style={styles.disclaimer}>*Required</Text> : <Text style={styles.errorMessage}>A valid zip is required.</Text>}
                </View>
              </View>
          ) : (
            <>
                <View style={[styles.rowInput, styles.input]}>
                  <TextInput
                      mode='outlined'
                      label='City'
                      theme={Theme}
                      style={styles.textField}
                      value={city}
                      error={cityError}
                      onChangeText={val => dispatch({
                        type: 'setCity',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateCity',
                        payload: e.target.value,
                      })}
                  />
                  {!cityError ? <Text style={styles.disclaimer}>*Required</Text> : <Text style={styles.errorMessage}>A valid city is required.</Text>}
                </View>
                <View style={[styles.rowInput, styles.input]}>
                  <TextInput
                      mode='outlined'
                      label='State'
                      theme={Theme}
                      style={styles.textField}
                      value={addressState}
                      error={addressStateError}
                      onChangeText={val => dispatch({
                        type: 'setAddressState',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateAddressState',
                        payload: e.target.value,
                      })}
                  />
                  <Text style={addressStateError ? styles.errorMessage : styles.disclaimer}>* Required</Text>
                </View>
                <View style={[styles.rowInput, styles.input]}>
                  <TextInput
                      mode='outlined'
                      label='Zip'
                      theme={Theme}
                      style={styles.textField}
                      value={zip}
                      error={zipError}
                      onChangeText={val => dispatch({
                        type: 'setZip',
                        payload: val,
                      })}
                      onBlur={e => dispatch({
                        type: 'validateZip',
                        payload: e.target.value,
                      })}
                  />
                  {!zipError ? <Text style={styles.disclaimer}>*Required</Text> : <Text style={styles.errorMessage}>A valid zip is required.</Text>}
                </View>
            </>
          )}

        </View>
        {roofData.map((data, i) => (
          <RoofDetails
            key={i}
            state={state}
            section={data.section}
            sectionText={data.text}
            name={data.name}
            handleRoofOptions={handleRoofOptions}
            isMobile={isMobile}
          />
        ))}
      </ScrollView>
      <QuoteFooter
        navigation={navigation}
        nextScreenLabel="Select New Roof Material"
        previousScreenLabel={'Back'}
        onPrevious={handleBack}
        onNext={handleNext}
      />
    </PaperProvider>
  );
};

const RoofDetails = ({ state, section, name, sectionText, handleRoofOptions, isMobile }) => {
  const getMobileCardMargins = (index) => {
    if (isMobile && index % 2 === 0) {
      return { marginRight: 10, marginBottom: 20 };
    }
    if (isMobile && index % 2 !== 0) {
      return { marginLeft: 10, marginBottom: 20 };
    }
  }
  const { hasError, value } = name === 'currentRoofType' ? { hasError: state.currentRoofTypeError, value: state.currentRoofType } : name === 'currentRoofPitch' ? { hasError: state.currentRoofPitchError, value: state.currentRoofPitch } : name === 'currentRoofCut' ? { hasError: state.currentRoofCutError, value: state.currentRoofCut } : { hasError: state.garageTypeError, value: state.garageType };
  return  (
    <View style={styles.section}>
      <Text style={styles.text}>{sectionText}</Text>
      <View style={isMobile ? mobileStyles.cardRow : styles.cardRow}>
        {section.map((data, i) => (
          <Card key={data.label} style={isMobile ? getMobileCardMargins(i) : styles.card} onPress={() => handleRoofOptions(name, data.label)}>
            <View style={[value === data.label ? styles.selectedRoofOption : styles.roofOption, hasError && styles.roofOptionError]}>
              <Card.Cover source={data.image} style={isMobile ? mobileStyles.cardImage : styles.cardImage} resizeMode={data.name === 'currentRoofCut' ? 'contain' : null} />
              <Card.Content style={value === data.label ? styles.cardContentSelected : styles.cardContent}>
                <Text style={styles.cardText}>{data.label}</Text>
                {
                  value === data.label &&
                  <CheckIcon style={{ color: 'white' }} />
                }
              </Card.Content>
            </View>
          </Card>
        ))}
      </View>
      <View style={styles.input}>
        <TextInput
          error={hasError}
          mode='outlined'
          label='Other'
          theme={Theme}
          style={[styles.textField, isMobile ? { maxWidth: 'none' } : styles.otherField]}
          onChangeText={value => handleRoofOptions(name, value)}
        />
        {!hasError ? <Text style={styles.disclaimer}>If not listed, please specify.</Text> : <Text style={styles.errorMessage}>Please select an option, or fill in the field above.</Text>}
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  screen: {
    padding: 50,
    backgroundColor: Colors.whiteBackground,
    flex: 1
  },
  section: {
    marginBottom: 30,
    maxWidth: 800,
  },
  title: {
    marginBottom: 30,
    fontSize: 22,
  },
  text: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
  },
  rowInput: {
    flex: 1,
  },
  input: {
    marginBottom: 20
  },
  textField: {
    backgroundColor: Colors.whiteBackground,
  },
  disclaimer: {
    marginTop: 5,
    marginLeft: 10,
    fontSize: 12,
    color: Colors.greyText,
  },
  cardImage: {
    backgroundColor: 'white',
  },
  card: {
    marginRight: 20,
    flex: 1,
    backgroundColor: '#daa622',
  },
  cardContent: {
    padding: 15,
    backgroundColor: '#9c9c9c',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContentSelected: {
    padding: 15,
    backgroundColor: Colors.elementSelected,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardText: {
    fontSize: 16,
    color: Colors.noticeText,
  },
  cardRow: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  overlay: {
    backgroundColor: Colors.elementSelected,
    opacity: 0.8,
  },
  addressSection: {
    maxWidth: 600,
  },
  addressField: {
    marginRight: 10,
  },
  otherField: {
    maxWidth: 300,
  },
  errorMessage: {
    fontSize: 12,
    paddingLeft: 20,
    color: Colors.errorText,
    marginTop: 5
  },
  roofOption: {
    borderWidth: 2,
    borderColor: 'rgba(255, 255, 255, 0)',
    borderStyle: 'solid',
  },
  selectedRoofOption: {
    borderWidth: 2,
    borderColor: Colors.elementSelected,
    borderStyle: 'solid',
  },
  roofOptionError: {
    borderColor: Colors.errorText,
  },
  checkIcon: {
    fontWeight: 600
  },
});

const mobileStyles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: Colors.whiteBackground,
    flex: 1
  },
  cardRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  cardImage: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    // height: 100,
  },
});

function reducer(state, action) {
  switch (action.type) {
    case 'setAddress':
      return {
        ...state,
        address: action.payload,
      }
    case 'validateAddress':
      return {
        ...state,
        addressError: !/^(?=.*\d)[a-zA-Z\s\d\/]+$/.test(state.address),
      }
    case 'setCity':
      return {
        ...state,
        city: action.payload,
      }
    case 'validateCity':
      return {
        ...state,
        cityError: !/^[a-zA-Z\s]+$/.test(state.city),
      }
    case 'setAddressState':
      return {
        ...state,
        addressState: action.payload,
      }
    case 'validateAddressState':
      return {
        ...state,
        addressStateError: !/^[a-zA-Z\s]+$/.test(state.addressState),
      }
    case 'setZip':
      return {
        ...state,
        zip: action.payload,
      }
    case 'validateZip':
      return {
        ...state,
        zipError: !/^\d{5}(?:[-\s]\d{4})?$/.test(state.zip),
      }
    case 'setFullAddress':
      return {
        ...state,
        address: action.payload.address,
        city: action.payload.city,
        addressState: action.payload.addressState,
        zip: action.payload.zip,
      }
    case 'setCurrentRoofType':
      return {
        ...state,
        currentRoofType: action.payload,
      }
    case 'validateCurrentRoofType':
      return {
        ...state,
        currentRoofTypeError: !state.currentRoofType.length,
      }
    case 'setCurrentRoofPitch':
      return {
        ...state,
        currentRoofPitch: action.payload,
      }
    case 'validateCurrentRoofPitch':
      return {
        ...state,
        currentRoofPitchError: !state.currentRoofPitch.length,
      }
    case 'setCurrentRoofCut':
      return {
        ...state,
        currentRoofCut: action.payload,
      }
    case 'validateCurrentRoofCut':
      return {
        ...state,
        currentRoofCutError: !state.currentRoofCut.length,
      }
    case 'setGarageType':
      return {
        ...state,
        garageType: action.payload,
      }
    case 'validateGarageType':
      return {
        ...state,
        garageTypeError: !state.garageType.length,
      }
    case 'validateAll':
      return {
        ...state,
        addressError: !/^(?=.*\d)[a-zA-Z\s\d\/]+$/.test(state.address),
        cityError: !/^[a-zA-Z\s]+$/.test(state.city),
        addressStateError: !/^[a-zA-Z\s]+$/.test(state.addressState),
        zipError: !/^\d{5}(?:[-\s]\d{4})?$/.test(state.zip),
        currentRoofTypeError: !state.currentRoofType?.length,
        currentRoofPitchError: !state.currentRoofPitch?.length,
        currentRoofCutError: !state.currentRoofCut?.length,
        garageTypeError: !state.garageType?.length,
      }
  }
}

export default RoofDetailsScreen;