import React from 'react';
import { Modal, Text } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

const TermsModal = (props) => {
  const { termsModalVisible, setTermsModalVisible } = props;

  return (
    <Modal
      visible={termsModalVisible}
      onDismiss={() => setTermsModalVisible(false)}
      contentContainerStyle={styles.modal}
    >
      <ScrollView style={{ flex: 1 }}>
        <Text style={styles.text}>Austin Roofing and Construction Privacy Policy &amp; Terms of Service</Text>
        <Text style={styles.header}>Privacy Policy</Text>
        <Text style={styles.subHeader}>Your privacy is important to us.</Text>
        <Text style={styles.text}>It is Austin Roofing and Construction&#39;s policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information. We have outlined our privacy policy below.</Text>
        <Text style={styles.text}>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</Text>
        <Text style={styles.text}>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</Text>
        <ol>
          <li>We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
          <li >Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
          <li >We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</li>
          <li >We will make readily available to customers information about our policies and practices relating to the management of personal information.</li>
          <li >We will only retain personal information for as long as necessary for the fulfillment of those purposes.</li>
        </ol>
        <Text style={styles.text}>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. Austin Roofing and Construction may change this privacy policy from time to time at Austin Roofing and Construction&#39;s sole discretion.</Text>
        <Text style={styles.header}>Terms</Text>
        <Text style={styles.text}>By accessing the website at <a href="https://www.austinroofingandconstruction.com/">https://www.austinroofingandconstruction.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</Text>
        <Text style={styles.text}>1. Use License</Text>
        <Text style={styles.text}>Permission is granted to temporarily download one copy of the materials (information or software) on Austin Roofing and Construction&#39;s website. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; attempt to de-compile or reverse engineer any software contained on Austin Roofing and Construction&#39;s website; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or &quot;mirror&quot; the materials on any other server.</Text>
        <Text style={styles.text}>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Austin Roofing and Construction at any time.</Text>
        <Text style={styles.text}>2. Disclaimer</Text>
        <Text style={styles.text}>The materials on Austin Roofing and Construction&#39;s website are provided on an &#39;as is&#39; basis. Austin Roofing and Construction makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Austin Roofing and Construction does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</Text>
        <Text style={styles.text}>3. Limitations</Text>
        <Text style={styles.text}>In no event shall Austin Roofing and Construction or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Austin Roofing and Construction&#39;s website. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</Text>
        <Text style={styles.text}>4. Accuracy of materials</Text>
        <Text style={styles.text}>The materials appearing on Austin Roofing and Construction&#39;s website could include technical, typographical, or photographic errors. Austin Roofing and Construction does not warrant that any of the materials on its website are accurate, complete or current. Austin Roofing and Construction may make changes to the materials contained on its website at any time without notice.</Text>
        <Text style={styles.text}>5. Links</Text>
        <Text style={styles.text}>Austin Roofing and Construction has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Austin Roofing and Construction of the site.</Text>
        <Text style={styles.text}>6. Modifications</Text>
        <Text style={styles.text}>Austin Roofing and Construction may revise these terms of service for its website at any time without notice.</Text>
        <Text style={styles.text}>7. Governing Law</Text>
        <Text style={styles.text}>These terms and conditions are governed by and construed in accordance with the laws of Texas and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</Text>
        <Text style={styles.text}>Copyright &copy; Austin Roofing and Construction &nbsp;</Text>
        <Text style={styles.text}>1301 W Ben White Blvd 200A Austin, Texas 78704</Text>
        <Text style={styles.text}>512-629-4949&nbsp; THE BEST ROOFING COMPANY IN AUSTIN</Text>
        <Text style={styles.text}>Local People Helping Local People</Text>
      </ScrollView>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    width: '80%',
    minWidth: 500,
    maxWidth: 800,
    backgroundColor: 'white',
    padding: 40,
    marginHorizontal: 'auto',
    height: 500
  },
  header: {
    marginBottom: 20,
    fontWeight: '500',
    fontSize: 20
  },
  text: {
    marginBottom: 10
  },
  subHeader: {
    fontWeight: '500',
    marginBottom: 10
  }
})

const mobileStyles = StyleSheet.create({
  modal: {
    width: '90%',
    maxWidth: 800,
    backgroundColor: 'white',
    padding: 40,
    marginHorizontal: 'auto',
    height: 500
  },
});

export default TermsModal;