import React, {useReducer} from 'react';
import {StyleSheet, Text, View, ScrollView} from 'react-native';
import { Provider as PaperProvider, TextInput } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';

import Stepper from '../navigation/Stepper';
import QuoteFooter from '../UI/QuoteFooter';
import Colors from '../../../constants/Colors';
import Theme from '../../../constants/Theme';

const ContactInfoScreen = ({ navigation, formData, onUpdateFormData, onCompletion, hasSentPartialInfo }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [state, dispatch] = useReducer(reducer, {
    firstName: formData.firstName || '',
    lastName: formData.lastName || '',
    email: formData.email || '',
    phone: formData.phone || '',
    agreedToTerms: formData.agreedToTerms || false,
  })

  const {
    firstName,
    lastName,
    email,
    phone,
    agreedToTerms,
    ...errors
  } = state;

  const {
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
    agreedToTermsError,
  } = errors;

  const isValid = firstName.length && !firstNameError && lastName.length && !lastNameError && email.length && !emailError && phone.length && !phoneError && agreedToTerms;

  const handleSendPartialInfo = () => {
    fetch('https://arc-estimator-api.herokuapp.com/api/quotes/partial', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        phone,
      }),
    })
  }

  const handleNext = () => {
    if (isValid) {
      onUpdateFormData({ ...formData, ...state });
      if (!hasSentPartialInfo) {
        handleSendPartialInfo();
        onCompletion();
      }
      navigation.navigate('RoofDetails')
    } else {
      dispatch({ type: 'validateAll' })
    }
  }

  return (
    <PaperProvider>
      <Stepper currentScreen={'ContactInfo'} />
      <ScrollView style={isMobile ? mobileStyles.screen : styles.screen}>
        <View style={styles.mainHeaderRow}>
          <Text style={styles.mainHeader}>Let's get started!</Text>
        </View>
        <View style={styles.subHeader}>
          <Text style={styles.header}>Please provide your contact info.</Text>
        </View>
        <View style={styles.text}>
          <Text style={styles.disclaimer}>Austin Roofing and Construction will never sell your information.</Text>
        </View>
        {!isMobile ? (
            <View style={styles.row}>
              <View style={styles.column}>
                <TextInput
                    theme={Theme}
                    error={firstNameError}
                    mode="outlined"
                    label="First Name"
                    value={firstName}
                    onChangeText={val => dispatch({ type: 'setFirstName', payload: val })}
                    onBlur={() => dispatch({ type: 'validateFirstName' })}></TextInput>
                {!firstNameError && <Text style={styles.requiredLabel}>* Required</Text>}
                {firstNameError && <Text style={styles.errorMessage}>A valid first name is required.</Text>}
              </View>
              <View style={styles.column}>
                <TextInput
                    theme={Theme}
                    error={lastNameError}
                    style={{ marginLeft: '10px' }}
                    mode="outlined"
                    label="Last Name"
                    value={lastName}
                    onChangeText={val => dispatch({ type: 'setLastName', payload: val })}
                    onBlur={() => dispatch({ type: 'validateLastName' })}></TextInput>
                {!lastNameError && <Text style={styles.requiredLabel}>* Required</Text>}
                {lastNameError && <Text style={styles.errorMessage}>A valid last name is required.</Text>}
              </View>
            </View>
        ) : (
            <>
              <View style={mobileStyles.spacer}>
                <TextInput
                    theme={Theme}
                    error={firstNameError}
                    mode="outlined"
                    label="First Name"
                    value={firstName}
                    onChangeText={val => dispatch({ type: 'setFirstName', payload: val })}
                    onBlur={() => dispatch({ type: 'validateFirstName' })}></TextInput>
                {!firstNameError && <Text style={styles.requiredLabel}>* Required</Text>}
                {firstNameError && <Text style={styles.errorMessage}>A valid first name is required.</Text>}
              </View>
              <View style={mobileStyles.spacer}>
                <TextInput
                    theme={Theme}
                    error={lastNameError}
                    mode="outlined"
                    label="Last Name"
                    value={lastName}
                    onChangeText={val => dispatch({ type: 'setLastName', payload: val })}
                    onBlur={() => dispatch({ type: 'validateLastName' })}></TextInput>
                {!lastNameError && <Text style={styles.requiredLabel}>* Required</Text>}
                {lastNameError && <Text style={styles.errorMessage}>A valid last name is required.</Text>}
              </View>
            </>
        )}
        <View style={isMobile ? mobileStyles.spacer : styles.spacer}>
          <TextInput
              theme={Theme}
              error={emailError}
              mode="outlined"
              label="Email"
              value={email}
              onChangeText={val => dispatch({ type: 'setEmail', payload: val })}
              onBlur={() => dispatch({ type: 'validateEmail' })}></TextInput>
          {email.length > 0 && emailError ? <Text style={styles.errorMessage}>A valid email is required.</Text> : <Text style={emailError && !email.length ? styles.errorMessage : styles.requiredLabel}>* Required</Text>}
        </View>
        <View style={isMobile ? mobileStyles.spacer : styles.spacer}>
          <TextInput
              theme={Theme}
              maxLength={16}
              error={phoneError}
              mode="outlined"
              label="Phone"
              value={phone}
              onChangeText={val => dispatch({ type: 'setPhone', payload: val })}
              onBlur={() => dispatch({ type: 'validatePhone' })}></TextInput>
          {<Text style={phoneError ? styles.errorMessage : styles.requiredLabel}>* Required</Text>}
        </View>
        <View style={styles.bottomRow}><Text style={{ color: 'white' }}>.</Text></View>
      </ScrollView>
      <QuoteFooter
          navigation={navigation}
          onNext={handleNext}
          nextScreenLabel="Provide Roof Details"
          setAgreedToTerms={val => dispatch({ type: 'setAgreedToTerms', payload: val })}
          agreedToTerms={agreedToTerms}
          agreedToTermsError={agreedToTermsError}
          showTermsAgreement
      />
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  screen: {
    padding: 50,
    backgroundColor: Colors.whiteBackground,
    flex: 1
  },
  section: {
    flex: 1,
    flexDirection: 'row',
    width: '50%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flex: 1
  },
  mainHeaderRow: {
    display: 'block',
    marginBottom: '3rem'
  },
  mainHeader: {
    fontSize: '24px'
  },
  subHeader: {
    display: 'block',
    marginBottom: '1rem'
  },
  header: {
    fontSize: '18px',
    display: 'block'
  },
  text: {
    marginBottom: '1rem'
  },
  disclaimer: {
    fontStyle: 'italic'
  },
  container: {
    display: 'block'
  },
  select: {
    height: 50,
    minWidth: 200,
    maxWidth: 300,
    marginTop: '2rem',
    backgroundColor: Colors.whiteBackground,
    borderRadius: 4,
    padding: 10
  },
  requiredLabel: {
    fontSize: '12px',
    paddingLeft: '10px',
    color: 'grey',
    marginTop: '5px'
  },
  errorMessage: {
    fontSize: '12px',
    paddingLeft: '10px',
    color: 'red',
    marginTop: '5px'
  },
  spacer: {
    marginTop: '2rem'
  },
  caption: {
    height: '200px',
    width: '200px',
    marginLeft: '50px',
    marginBottom: '20px',
    backgroundColor: '#CCCCCC',
    textAlign: 'center'
  },
  bottomRow: {
    marginBottom: '4rem'
  }
});

const mobileStyles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: Colors.whiteBackground,
    flex: 1
  },
  spacer: {
    marginTop: '1rem'
  },
  select: {
    height: 50,
    minWidth: 200,
    marginTop: '2rem',
    backgroundColor: Colors.whiteBackground,
    borderRadius: 4,
    padding: 10
  },
});

function reducer(state, action) {
  switch (action.type) {
    case 'setFirstName':
      return {
        ...state,
        firstName: action.payload,
      }
    case 'validateFirstName':
      return {
        ...state,
        firstNameError: state.firstName.length === 0,
      }
    case 'setLastName':
      return {
        ...state,
        lastName: action.payload,
      }
    case 'validateLastName':
      return {
        ...state,
        lastNameError: state.lastName.length === 0,
      }
    case 'setEmail':
      return {
        ...state,
        email: action.payload,
      }
    case 'validateEmail':
      return {
        ...state,
        emailError: !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email),
      }
    case 'setPhone':
      return {
        ...state,
        phone: action.payload,
      }
    case 'validatePhone':
      return {
        ...state,
        phoneError: !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(state.phone),
      }
    case 'setAgreedToTerms':
      return {
        ...state,
        agreedToTerms: action.payload,
        agreedToTermsError: action.payload ? false : state.agreedToTermsError,
      }
    case 'validateAgreedToTerms':
      return {
        ...state,
        agreedToTermsError: !state.agreedToTerms,
      }
    case 'validateAll':
      return {
        ...state,
        firstNameError: state.firstName.length === 0,
        lastNameError: state.lastName.length === 0,
        emailError: !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email),
        phoneError: !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(state.phone),
        agreedToTermsError: !state.agreedToTerms,
      }
    default:
      return state;
  }
}

export default ContactInfoScreen;