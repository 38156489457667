import React from 'react';
import { Button } from 'react-native';
import { StyleSheet, Text, View, Image } from 'react-native';
import { RadioButton } from 'react-native-paper';

import Colors from '../../../constants/Colors';

const NewRoofOption = ({label, details, image, id, checked, handleTypeChange}) => {
    const styles = StyleSheet.create({
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      imgTextContainer: {
        alignItems: 'center',
        marginLeft: '2rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '700px'
      },
      image: {
        flex: 1,
        width: '100px',
        maxWidth: '100px',
        height: '100px',
        marginRight: '2rem',
        borderRadius: '5px',
      },
      textContainer: {
        flex: 1
      },
      label: {
        fontSize: '16px',
        display: 'block'
      },
      details: {
        lineHeight: '18px',
        fontSize: '14px',
        color: Colors.greyText,
        display: 'block',
        marginTop: '7px'
      }
    });
  
    return (
      <View style={styles.row}>
        <RadioButton
          color={Colors.elementSelected}
          value={id}
          status={checked === id ? 'checked' : 'unchecked'} 
          onPress={() => { handleTypeChange(id) }} />
        <View style={styles.imgTextContainer}>
          <Image source={image} style={styles.image}></Image>
          <View style={styles.textContainer}>
            <Text style={styles.label}>{label}</Text>
            <Text style={styles.details}>{details}</Text>
          </View>
        </View>
      </View>
    )
  };

  export default NewRoofOption;