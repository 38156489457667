import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

import Colors from '../../../constants/Colors';
import ARCHandsLogo from '../../../assets/images/ARCHandsLogo_Gray_Transparent.png';

const QuoteHeader = props => {

  const navigateHome = () => (
    window.location.href = 'https://www.austinroofingandconstruction.com/'
  );

  return (
    <View style={styles.header}>
      <Image source={ARCHandsLogo} style={styles.logo} />
      <Text style={styles.headerTitle}>{props.title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: '100%',
    paddingVertical: 20,
    paddingHorizontal: 30,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: Colors.darkBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTitle: {
    color: Colors.lightBackground,
    fontSize: 20,
    marginLeft: 40
  },
  logo: {
    resizeMode: 'cover',
    width: '50px',
    height: '50px',
    position: 'absolute',
    top: '6px',
    left: '6px'
  }
});

export default QuoteHeader;