import * as React from 'react';

import Quote from './components/Quote/Quote';
import { NativeBaseProvider } from 'native-base';

export default function App() {
  return (
    <NativeBaseProvider>
      <Quote />
    </NativeBaseProvider>
  );
}
