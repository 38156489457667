import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import * as Linking from 'expo-linking';
import { ScrollView, StyleSheet, View, Text, Image, Alert } from 'react-native';
import {
  Provider as PaperProvider,
  ActivityIndicator,
  Portal,
  Modal,
} from 'react-native-paper';
import { Button, ChevronRightIcon } from 'native-base';
import { useMediaQuery } from 'react-responsive'
import Stepper from '../navigation/Stepper';
import QuoteFooter from '../UI/QuoteFooter';
import idealMetal from '../../../assets/images/idealMetal.jpg'
import ideal50YrShingle from '../../../assets/images/ideal50YrShingle.png'
import idealBasicShingle from '../../../assets/images/idealBasicShingle.jpg'

import { roofMaterials } from './constants/roofData'

import Colors from '../../../constants/Colors';

import Theme from '../../../constants/Theme';

const quoteImages = {
  basic_shingle: idealBasicShingle,
  '50_year_shingle': ideal50YrShingle,
  standing_seam_metal: idealMetal
}

const hearthURL = "https://www.ffcapplication.com/?i=6Iaul1MJQO8fGv9MYRjYvnHfYjOsPKOzVcbVYmRExzM=";

const OpenURLButton = ({ url, children, isMobile }) => {
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url, '_blank');
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, [url]);

  return (
    <Button
      style={isMobile ? mobileStyles.disclaimerButton : styles.disclaimerButton}
      iconRight
      onPress={handlePress}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.disclaimerButtonText}>Get My Rates</Text>
        <ChevronRightIcon />
      </View>
    </Button>
  );
};

const ReviewQuoteScreen = ({ navigation, formData, quoteData, onUpdateQuoteData }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const componentRef = useRef();

  const [isLoading, setLoading] = useState(true);
  const [finishModalVisible, setFinishModalVisible] = useState(false);

  useEffect(() => {
    fetch('https://arc-estimator-api.herokuapp.com/api/quotes', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        state: formData.addressState,
        city: formData.city,
        zip: formData.zip,
        currentRoofType: formData.currentRoofType,
        currentRoofPitch: formData.currentRoofPitch,
        currentRoofCut: formData.currentRoofCut,
        garageType: formData.garageType,
        dreamRoofType: formData.newRoofType,
      }),
    })
      .then((res) => res.json())
      .then((data) => onUpdateQuoteData(data))
      .catch((err) => Alert.alert(err.message))
      .finally(() => setLoading(false));
  }, []);

  const displayFinishModal = () => {
    setFinishModalVisible(true);
  };

  const goToHome = () => {
    // Clear out all of the state data
    window.location.href = 'https://www.austinroofingandconstruction.com/about-This-Austin-Roofing-Company';
  };

  return (
    <PaperProvider>
      <Stepper currentScreen={'ReviewQuote'} />
      <ScrollView style={isMobile ? mobileStyles.screen : styles.screen}>
        {isLoading ? (
          <View style={styles.spinner}>
            <ActivityIndicator theme={Theme} size='large' animating />
          </View>
        ) : (
          <View style={isMobile ? mobileStyles.screen : styles.screen}>
            <View style={styles.headerSection}>
              <View>
                <Text style={styles.header}>We can't wait to get started!</Text>
              </View>
              <View>
                <Text style={styles.subheader}>
                  We'll be in touch soon. In the meantime, check your inbox for a copy
                  of your quote and let us know if you have a favorite option below.
                </Text>
              </View>
              <View style={isMobile ? mobileStyles.disclaimerRow : styles.disclaimerRow}>
                <Text style={isMobile ? mobileStyles.disclaimerText : styles.disclaimerText}>
                  Finance it! 2-Minute Pre-Qualification with Foundation Finance - Does Not Affect Credit
                </Text>
                <OpenURLButton url={hearthURL} isMobile={isMobile}>Get My Rates</OpenURLButton>
              </View>
            </View>
            <QuoteSummary
              quoteData={quoteData || {}}
              ref={componentRef}
              isMobile={isMobile}
            />
          </View>
        )}
      </ScrollView>
      <Portal>
        <Modal
          visible={finishModalVisible}
          onDismiss={() => setFinishModalVisible(false)}
          contentContainerStyle={styles.modal}
        >
          <Text style={styles.header}>Thank you!</Text>
          <Text style={styles.subheader}>
            Your quote will be emailed to you shortly.
          </Text>
          <View style={styles.homeButton}>
            <Button style={styles.nextButton} onPress={goToHome} iconRight>
              <Text style={styles.nextText}>HOME</Text>
            </Button>
          </View>
        </Modal>
      </Portal>
      <QuoteFooter
        navigation={navigation}
        nextScreenLabel={'Finish'}
        onNext={displayFinishModal}
      />
    </PaperProvider>
  );
};

const QuoteSummary = forwardRef((props, ref) => {
  const { quoteData, isMobile } = props;
  return (
    <View ref={ref}>
      <Text style={styles.quoteHeaderText}>Austin Roofing & Construction - New Roof Quote</Text>
      <Text style={styles.emailLink} onPress={() => Linking.openURL('tel:512-629-4949')}>512-629-4949</Text>
      {Object.keys(quoteData).map((key) => (
        <View key={key}>
          {key === 'basic_shingle' && (
            <QuoteOption
              type={key}
              quoteData={quoteData}
              isMobile={isMobile}
            />
          )}
          {key === '50_year_shingle' && (
            <QuoteOption
              type={key}
              quoteData={quoteData}
              isMobile={isMobile}
            />
          )}
          {key === 'standing_seam_metal' && (
            <QuoteOption
              type={key}
              quoteData={quoteData}
              isMobile={isMobile}
            />
          )}
        </View>
      ))}

    </View>
  )
})


const QuoteOption = ({
  type,
  quoteData,
  isMobile
}) => {

  const roofContent = (type) => {
    return roofMaterials.find((x) => x.quoteId === type);
  }

  return (
    <View style={styles.quote}>
      <Image source={quoteImages[type]} style={styles.image}></Image>
      <View style={styles.quoteDetails}>
        <View style={styles.quoteType}>
          <Text style={styles.roofType}>{roofContent(type).id}</Text>
        </View>
        <View style={styles.detail}>
          <Text style={styles.supportDetail}>
            {roofContent(type).simpleLabel}
          </Text>
          <Text style={styles.totalCost}>{`$${quoteData[type].total_cost}`}</Text>
        </View>
        <View>
          <View style={styles.monthlyCostRow}>
            <Text style={styles.monthlyCostText}>
              Payments as low as
            </Text>
            {isMobile ? (
              <Text>{'  '}</Text>
            ) : (
              <View style={styles.spacer} />
            )}
            <Text
              style={styles.monthlyCost}
            >{`$${quoteData[type].monthly_cost}`}</Text>
          </View>
          <View>
            <Text style={isMobile ? mobileStyles.support : styles.support}>
              {roofContent(type).details}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  quoteHeaderText: {
    fontWeight: '800',
    marginBottom: 10
  },
  emailLink: {
    fontWeight: '800',
    marginBottom: 10
  },
  screen: {
    padding: 50,
    flex: 1,
    backgroundColor: Colors.whiteBackground,
  },
  quoteSection: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    padding: 20
  },
  printText: {
    opacity: 0
  },
  spinner: {
    height: '50vh',
    justifyContent: 'center',
  },
  headerSection: {
    paddingBottom: 10,
    marginBottom: 30,
    borderBottomWidth: 1,
    borderBottomColor: Colors.tabIconDefault,
    borderStyle: 'solid',
  },
  header: {
    fontSize: 24,
    marginBottom: 30,
  },
  subheader: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 30,
  },
  disclaimerRow: {
    flexDirection: 'row',
  },
  disclaimerText: {
    margin: 'auto',
    marginLeft: 20,
    color: Colors.greyText,
  },
  disclaimerButton: {
    paddingLeft:15,
    backgroundColor: Colors.lightBackground,
    borderRadius: '4px',
    borderColor: '#dddddd',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  disclaimerButtonText: {
    fontWeight: '600',
    paddingRight: 10
  },
  printQuote: {
    width: 'fit-content',
    marginBottom: 20,
    backgroundColor: Colors.whiteBackground,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.tabIconDefault,
    borderRadius: 5,
  },
  printQuoteText: {
    fontWeight: '600',
  },
  quote: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.tabIconDefault,
    borderStyle: 'solid',
  },
  quoteDetails: {
    flex: 1,
    margin: 'auto',
  },
  quoteType: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  roofType: {
    flex: 1,
    margin: 'auto',
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: '600'
  },
  totalCost: {
    fontSize: 14,
    color: Colors.greyText,
  },
  detail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  supportDetail: {
    color: Colors.greyText,
  },
  monthlyCostRow: {
    marginBottom: 15,
    flexDirection: 'row',
  },
  monthlyCostText: {
    color: Colors.greyText,
    fontWeight: '800'
  },
  monthlyCost: {
    fontSize: 14,
    fontWeight: '800'
  },
  spacer: {
    height: 10,
    borderBottomColor: Colors.elementSelected,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
  },
  support: {
    color: Colors.greyText,
    marginBottom: 15,
  },
  favorite: {
    width: 160,
    backgroundColor: Colors.whiteBackground,
    height: 38,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: Colors.tabIconDefault,
    borderRadius: 5,
  },
  favoriteIcon: {
    color: Colors.blackText,
    marginRight: 0,
  },
  favoriteText: {
    fontWeight: '600',
  },
  iconButton: {
    marginRight: 10,
  },
  image: {
    flex: 1,
    width: 50,
    maxWidth: 50,
    height: 50,
    marginRight: '1rem',
    borderRadius: 5,
  },
  modal: {
    width: '60%',
    minWidth: 500,
    maxWidth: 800,
    backgroundColor: Colors.whiteBackground,
    padding: 40,
    marginHorizontal: 'auto',
  },
  homeButton: {
    alignItems: 'flex-end',
  },
  nextButton: {
    backgroundColor: Colors.elementSelected,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: Colors.elementSelected,
    borderRadius: 5,
    width: 100,
  },
  nextText: {
    fontWeight: '600',
    paddingHorizontal: 10,
    color: 'white',
  },
  nextArrow: {
    marginLeft: 0,
    color: 'black',
  },
});

const mobileStyles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    padding: 20
  },
  quoteSection: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
  },
  support: {
    color: Colors.greyText,
    marginBottom: 15,
  },
  disclaimerText: {
    color: Colors.greyText,
    marginBottom: 20
  },
  modal: {
    width: '80%',
    maxWidth: 800,
    backgroundColor: Colors.whiteBackground,
    padding: 40,
    marginHorizontal: 'auto',
  },
  disclaimerRow: {
    flexDirection: 'column',
  },
  disclaimerButton: {
    paddingLeft:15,
    backgroundColor: Colors.lightBackground,
    marginBottom: 20,
    width: 150
  },
});

export default ReviewQuoteScreen;
