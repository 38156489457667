import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Checkbox, Portal } from 'react-native-paper';
import {Button, ChevronLeftIcon, ChevronRightIcon} from 'native-base';
import TermsModal from './TermsModal'
import { useMediaQuery } from 'react-responsive'

import Colors from '../../../constants/Colors';

const QuoteFooter = ({
  agreedToTerms,
  setAgreedToTerms,
  agreedToTermsError,
  onPrevious,
  onNext,
  nextScreenLabel,
  previousScreenLabel,
  showTermsAgreement,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  const getFlexDirection = () => {
    if (!onPrevious && isMobile) {
      return { flexDirection: 'column', flex: 1 }
    } else if (!!onPrevious && isMobile) {
      return {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10
      }
    } else {
      return {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
      }
    }
  }

  const mobileNextLabel = () => {
    if (nextScreenLabel === 'Finish') return 'finish';
    return 'next';
  }

  return (
      <View style={isMobile ? mobileStyles.footer : styles.footer}>
        <View style={getFlexDirection()}>
        {showTermsAgreement && setAgreedToTerms ?
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                color={Colors.elementSelected}
                status={agreedToTerms ? 'checked' : 'unchecked'}
                onPress={() => setAgreedToTerms(!agreedToTerms)}
              />
              <Text style={styles.termsText}>
                I agree to the <Text style={styles.linkText} onPress={() => setTermsModalVisible(true)}>terms and conditions.</Text>
              </Text>
            </View>
             {agreedToTermsError ? <Text style={styles.errorMessage}>Please agree to the terms & conditions to proceed.</Text> : null}
          </View>
          : onPrevious ?
          <Button
            style={styles.previousButton}
            onPress={onPrevious}
            iconLeft
          >
            <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
            <ChevronLeftIcon />
            <Text style={styles.prevText}>{previousScreenLabel}</Text>
            </View>
          </Button>
                : <View></View>
        }
        <Button
          style={{
            backgroundColor: Colors.elementSelected,
            paddingLeft: 15,
            borderWidth: 1,
            borderColor: Colors.elementSelected,
            borderRadius: 5,
            height: 40,
          }}
          onPress={onNext}
        >
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
          }}>
            <Text style={isMobile ? mobileStyles.nextText : styles.nextText}>{isMobile ? mobileNextLabel() : nextScreenLabel}</Text>
            {/*<Text style={styles.nextText}>{nextScreenLabel}</Text>*/}
            <ChevronRightIcon style={{ color: 'white' }} />
          </View>
          {/*<Icon style={styles.nextArrow} name="chevron-right" />*/}
        </Button>
      </View>
      <Portal>
        <TermsModal {...{ termsModalVisible, setTermsModalVisible }} />
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 15,
    paddingHorizontal: 30,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: Colors.lightBackground,
    position: 'absolute',
    bottom: 0,
  },
  cancelButton: {
    paddingHorizontal: 10,
    backgroundColor: Colors.lightBackground,
  },
  previousButton: {
    backgroundColor: Colors.lightBackground,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5
  },
  nextButton: {
    backgroundColor: Colors.elementSelected,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: Colors.elementSelected,
    borderRadius: 5,
  },
  cancelText: {
    fontWeight: '600',
    paddingHorizontal: 10
  },
  prevText: {
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  nextText: {
    fontWeight: '600',
    color: 'white',
    textTransform: 'uppercase'
  },
  nextArrow: {
    marginLeft: 10,
    marginRight: 10,
    color: 'white'
  },
  prevArrow: {
    marginRight: 10,
    color: 'black'
  },
  nextContainer: {
    flexDirection: 'row'
  },
  termCheckbox: {
    marginRight: 5,
    color: Colors.elementSelected
  },
  linkText: {
    color: '#117BFF'
  },
  errorMessage: {
    fontSize: '12px',
    paddingLeft: '20px',
    color: 'red',
  },
});

const mobileStyles = StyleSheet.create({
  footer: {
    flexDirection: 'column-reverse',
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.lightBackground,
    bottom: 0
  },
  nextContainer: {
    flexDirection: 'column'
  },
  nextButton: {
    backgroundColor: Colors.elementSelected,
    paddingLeft: 15,
    borderWidth: 1,
    borderColor: Colors.elementSelected,
    borderRadius: 5,
    margin: 'auto',
    marginBottom: 10,
    marginTop: 8,
    height: 40
  },
  nextText: {
    fontWeight: '600',
    paddingHorizontal: 10,
    color: 'white',
    textTransform: 'uppercase',
    paddingRight: 4
  },
});

export default QuoteFooter;