const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#b00020',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  greyText: '#6e6e6e',
  blackText: '#000',

  darkBackground: '#666',
  lightBackground: '#EEE',
  whiteBackground: '#FFF',
  elementSelected: '#DAA522',
  elementUnselected: '#939393'
};