import { useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import QuoteHeader from './UI/QuoteHeader';
import ContactInfoScreen from './Screens/ContactInfoScreen';
import ReviewQuoteScreen from './Screens/ReviewQuoteScreen';
import RoofMaterialScreen from './Screens/RoofMaterialScreen';
import RoofDetailsScreen from './Screens/RoofDetailsScreen';

import './_global.css';

const Stack = createNativeStackNavigator();

const Quote = () => {
  const [initialNavigationState, setInitialNavigationState] = useState()
  const [hasSentPartialInfo, setHasSentPartialInfo] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    preferredContactMethod: '',
    agreedToTerms: false,
    address: '',
    city: '',
    addressState: '',
    zip: '',
    currentRoofType: '',
    currentRoofPitch: '',
    currentRoofCut: '',
    garageType: '',
    newRoofType: '',
    screenValidity: {
      contactInfo: false,
      roofDetail: false,
      roofMaterial: false
    }
  })
  const [quoteData, setQuoteData] = useState();

  const containerRef = useRef();

  return (
    <View style={styles.container}>
      <QuoteHeader title="New Roof Quote" />
      <NavigationContainer ref={containerRef} initialState={initialNavigationState}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            animationEnabled: false
          }}
        >

          <Stack.Screen name="ContactInfo">
            {props => <ContactInfoScreen {...props} formData={formData} onUpdateFormData={(newFormData) => setFormData(newFormData)} onCompletion={() => setHasSentPartialInfo(true)} hasSentPartialInfo={hasSentPartialInfo} />}
          </Stack.Screen>

          <Stack.Screen name="RoofDetails">
            {props => <RoofDetailsScreen {...props} formData={formData} onUpdateFormData={(newFormData) => setFormData(newFormData)} />}
          </Stack.Screen>

          <Stack.Screen name="RoofMaterial">
            {props => <RoofMaterialScreen {...props} formData={formData} onUpdateFormData={(newFormData) => setFormData(newFormData)} />}
          </Stack.Screen>

          <Stack.Screen name="ReviewQuote">
            {props => <ReviewQuoteScreen {...props} formData={formData} quoteData={quoteData} onUpdateQuoteData={(newQuoteData) => setQuoteData(newQuoteData)} />}
          </Stack.Screen>

        </Stack.Navigator>
      </NavigationContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});

export default Quote;